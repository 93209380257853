@import './sass-vars.scss';

.textFields {
    @media screen and (min-resolution: 192dpi) and (max-width: $big-laptop) {

        label {
            font-size: $big-laptop-fontsize;
            transform: translate(14px, -7px) scale(0.75)
        }
    }

    .inputWrap { 
        @media screen and (min-resolution: 192dpi) and (max-width: $big-laptop) {
            input, textarea {
                font-size: $big-laptop-fontsize;
            }
        }
    }

    legend {
        span {
            @media screen and (min-resolution: 192dpi) and (max-width: $big-laptop) {
                padding-right: 2px;
                padding-left: 2px;
            }
        }
    }
}