@import '../../../../sass/sass-vars.scss';

.wrap {
    section {
        width: 20rem;
        margin: 3rem 0;

        @media screen and (min-resolution: 192dpi) and (max-width: $big-laptop) {
            span {
                font-size: 13.5px;
            }
        }

        h3 {
            margin: 0;
        }
    }
}