@import '../sass/sass-vars.scss';
.wrap {
    width: 100%;
    
    img {
        max-width: 100%;
        margin-top: 1rem;
        max-height: 350px;
    }

    .btn {
        @media screen and (min-resolution: 192dpi) and (max-width: $big-laptop) {
            font-size: 13.5px;
        }
    }
}

.warning {
    font-weight: bold;
    font-style: italic;
    color: #e4b800;
    font-size: 14px;
    padding-left: 6px;
}