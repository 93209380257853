@import '../../../../sass/sass-vars.scss';

.wrap {
    width: 260px !important;
    margin-bottom: 2rem;
    background-color: #262626;
    .innerWrap {
        padding: 12px;
        display: flex;
        width: 260px !important;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        gap: 1rem;

        .title {
            font-style: italic;
            font-weight: 700;
            border-bottom: 1px solid;
            width: 100%;
            margin-bottom: 5px;
            line-height: 1.75;
        }

        span {
            display: flex;
            gap: 1rem;
        }

        .dropdownHelper {
            color: rgba(255, 255, 255, 0.7);
            font-family: "Roboto","Helvetica","Arial",sans-serif;
            font-weight: 400;
            font-size: 0.75rem;
            line-height: 1.66;
            letter-spacing: 0.03333em;
            text-align: left;
            margin-top: 4px;
            margin-right: 14px;
            margin-bottom: 0;
            margin-left: 7px;
        }

        @media screen and (max-width: $iphone-plus) {
            flex-direction: column;
        }
    }
    @media screen and (max-width: $iphone-plus) {
        width: 350px;
        max-width: 100%;
    }
}

.option {
    font-size: 13px !important;
}