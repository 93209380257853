@import '../../../../sass/sass-vars.scss';

.wrap {
    width: $design-card-width;
    height: $design-card-height;
    max-height: $design-card-height;
    flex-shrink: 0;
    position: relative;


    .image {
        object-fit: contain;
        object-position: top;
        height: 100%;
    }

    .designTextWrap {
        height: 140px;
        width: calc(100%);
        border-bottom: 2px solid rgb(26, 26, 26);
        padding: 14px;
        background: #f1f1f1;

        div {
            max-height: calc(90%);
            font-weight: bold;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4;
            line-break: anywhere;
        }
    }
    
    

    .btmWrap {
        position: absolute;
        bottom: 0;
        width: 100%;

        .content {
            padding-bottom: 8px;
            background: #ffffff91;

            .nameWrap {
                display: inline-flex;
                line-height: 20px;
        
                div {
                    font-size: 20px;
                    line-height: 20px;
                }
            }
        
            .commentsWrap {
                display: -webkit-box;
                text-overflow: ellipsis;
                overflow: hidden;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                p {
                    height: 2.5em;
                    line-height: 0.75em;
                    font-size: 0.75em;
                    font-weight: 300;
                    font-style: italic;
                }
            }

            .clicksPropsWrap {
                font-size: 0.75em;
                font-weight: 300;
                font-style: italic;
                p {
                    margin: 0.2rem 0;
                }
            }

            .btm {
                font-style: italic;
                text-transform: uppercase;
    
                p {
                    font-weight: bold;
                    font-size: 0.75em;
                }
            }
        }
        

        .btns {
            padding-left: 2px;
            padding-top: 4px;
            padding-bottom: 4px;
            background-color: #ffffff91;
            button {
                font-size: 11px;
                &:hover {
                    background-color: $main-bg-color;
                }
            }
        }

        .btnsDark {
            padding-left: 2px;
            padding-top: 4px;
            padding-bottom: 4px;
            background-color: #0000009e;

            button {
                font-size: 11px;
                &:hover {
                    background-color: rgb(15, 15, 15);
                }
            }
        }
    }
}

.lowerContent {
    display: flex;
    flex-direction: column;
    width: 280px;
    overflow: scroll;
    font-size: 11px;
}

.topPerformer {
    background: #5eba7d;
}



.flexContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
