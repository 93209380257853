.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 80vh;
    border-radius: 6px;
    box-shadow: 24px;
    overflow: scroll;
    padding: 1.7rem;
}