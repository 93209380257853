$outer-spacing: 7rem;
$side-panel-space: 14rem;
$main-left-space: 18rem;
$main-right-space: 6rem;
$inner-stepper-height: 600px;
$main-bg-color: #f9f9f9;
$alternate-blue: #1786e7;



// media query results
$big-laptop: 1680px;
$big-laptop-fontsize: 13px;
$normal-laptop: 1280px;
$small-laptop: 1024px;
$small-laptop-margin: 11.5rem;
$tablet: 768px;
$tablet-margin: 5rem;
$iphone-plus: 414px;
$iphone-pro-max: 428px;
$iphone-plus-margin: 0.75rem;


// design card
$design-card-height: 405px;
$design-card-width: 225px;
