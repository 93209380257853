@import '../sass/sass-vars.scss';

.wrap {
    margin: 4rem 0;


    .dialogWrap {
        @media screen and (max-width: $iphone-plus) {
            text-align: center;
        }
        .btnsWrap {
            @media screen and (max-width: $iphone-plus) {
                justify-content: center;
            }
        }
    }
    
}