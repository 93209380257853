.wrap {

    margin: 0.75rem 0;
    padding: 0;
    height: calc(115px + 1rem);

    .innerWrap {
        display: flex;
        gap: 2rem;
        height: calc(100%);
        

        .imgWrap, .infoWrap {
            width: 50%;
            max-height: calc(100%);

            img {
                max-height: calc(100%);
                max-width: calc(100%);
                object-fit: contain;
            }
        }

        .imgWrap {
            display: flex;
            justify-content: center;
        }
        .infoWrap {
            h1 {
                margin-top: 5px;
                font-size: 22px;
            }
            div {
                font-size: 16px;
            }
        }
    }
}