.wrap {
    flex: 1;

    .tabList {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    .tabPanel {
        padding: 0;
        height: calc(100% - 48px);
    }
}