
@import '../../../sass/sass-vars.scss';

.wrap {

    height: 100%;
    margin-bottom: 3rem;
    @media screen and (max-width: $normal-laptop) {
        width: 100%;
    }

    .titleWrap {
        @media screen and (max-width: $iphone-pro-max) {
            text-align: center;
        }
        
        .title {
            padding: 0 1.5rem;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            h2 {
                border-bottom: 1px solid;
            }

            span {
                font-weight: bold;
                font-style: italic;
            }
            @media screen and (max-width: $tablet) {
                justify-content: center;
                flex-direction: column;
            }

            @media screen and (max-width: $iphone-plus) {
                margin: 0.5rem 0;
            }

            .dbBtnsWrap {
                display: flex;
                gap: 1rem;
                @media screen and (max-width: $iphone-pro-max) {
                    justify-content: space-between;                    
                }
            }
        }

        div {
            font-size: 14px;
            margin-bottom: 1rem;
        }
    }
    
    .emptyData {
        padding: 0 1.5rem;

        span {
            font-size: 14px;
            font-weight:unset;
        }
    }

    tspan {
        font-size: 12px;
        font-weight: bold;
    }
}

.tick {
    font-size: 30px;
}