.wrap {
    height: 50vh;
    width: 100%;

    .specialCol {
        background-color: white !important;
    }
}

.rowClass {
    cursor: pointer;

    div {
        &:focus, &:active {
            outline: inherit !important;
        }
    }
}