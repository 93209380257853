@import '../../../sass/sass-vars.scss';

.btnWrapper {
    margin-left: 5px;

    .addBtn {
        @media screen and (min-resolution: 192dpi) and (max-width: $small-laptop) {
            line-height: 1;
        }
    }
}