
.sectionWrap {

    max-width: 260px !important;
    
    section {
        margin: 0;
        display: flex;
        flex-direction: column;
        width: 260px !important;
        max-width: 260px !important;
        .pickerWrap {
            display: flex;
            justify-content: space-between;
        }

        .btnsWrap {
            display: flex;
            justify-content: space-between;
        }
    }
}
