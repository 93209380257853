.wrap {
    .accordionWrap {
        margin-bottom: 2rem !important;
    }

    .accordionSummaryContent {
        margin: 0 !important;
    }

    .detailsWrap {
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;
    }
}