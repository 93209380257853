.wrap {
    display: flex;

    .tabList {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    .tabPanel {
        padding: 0;
        width: 100%;
    }
}