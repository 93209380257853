@import '../../../sass/sass-vars.scss';

.wrap {
        
    @media screen and (max-width: $tablet) {
        &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 4px;
        }
        
        &::-webkit-scrollbar-track {
            background-color: rgba(128, 128, 128, 0.6);
            border-radius: 8px;
        }
        
        &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            background-color: rgba(241, 241, 241, 0.6);
        }
    }
    
    height: $design-card-height + 65px;
    padding-left: 2px;
    display: flex;
    flex-direction: row;
    margin: -1rem;
    overflow-x: scroll;
    overflow-y: hidden;
    & > * {
        margin: 1rem;
    }
}

