@import '../../sass/sass-vars.scss';

.wrap {
    width: 35%;
    padding-right: 3rem;

    @media screen and (max-width: $normal-laptop) {
        display: flex;
        padding: 0;
        width: 100%;
    }
    
    .outerWrap {
        height: 100%;

        th {
            font-size: 18px;
    
            td {
                font-weight: bold;
            }
        }
    
        .innerCollapse {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 1rem 0;
        }
    }
    
}