@import '../sass/sass-vars.scss';

.wrap {
    display: flex;
    flex-direction: column;


    hr {
        width: 100%;
    }

    .summary {
        display: flex;

        @media screen and (max-width: $normal-laptop) {
            display: flex;
            flex-direction: column;
            align-items: center;
            grid-gap: 2rem;
            gap: 2rem;
        }
    }
}