@import '../sass/sass-vars.scss';
.wrap {
    display: flex;
    flex-direction: column;
    padding-bottom: 5rem;


    .btns {
        display: flex;
        align-items: center;
        gap: 2rem;
        grid-gap: 2rem;
        

        @media screen and (max-width: ($iphone-pro-max + 10)) {
            justify-content: center;
        }
    }
}