@import './sass/sass-vars.scss';
.wrap {

    .burger {
        position: absolute;
        padding: 1rem;

        @media screen and (max-width: ($iphone-pro-max + 10)) {
            padding: 0;
        }
    }
}