.wrap {
    margin: 0.75rem 0;

    .link {
        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .projectsPage {
        display: flex;
        &:hover {
            text-decoration: none;
            cursor: default;
        }
    }

    .seperator {
        margin-left: 8px;
        margin-right: 8px;
        display: flex;
        user-select: none;
    }
}