.wrap {
    section {
        width: 20rem;
        margin: 3rem 0;

        .formGroupWrap {
            width: 100%;
            
            .radioGroup {
                flex-direction: row !important;

                .label {
                    font-weight: bold;
                }
            }
        }
    }
}