@import '../sass/sass-vars.scss';

.wrap {
    
    .navWrap {
        display: flex;
        gap: 2rem;

        @media screen and (max-width: $iphone-pro-max) {
            flex-direction: column;
        }

        .navBtn {
            padding: 5rem;
            cursor: pointer;
            font-size: 18px;
            font-weight: bold;
            text-align: center;

            span {
                display: flex;
                align-items: center;
                gap: 5px;
                justify-content: center;
            }
        }
    }
}