@import '../sass/sass-vars.scss';

.addCardLight {
    height: $design-card-height;
    max-height: $design-card-height;
    width: $design-card-width;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    flex-shrink: 0;
    &:hover {
        background-color:#e4e4e4;
    }
    h3 {
        color: $alternate-blue;
    }
}

.addCardDark {
    height: $design-card-height;
    max-height: $design-card-height;
    width: 225px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    flex-shrink: 0;
    &:hover {
        background-color:#888888;
    }
    h3 {
        color: $alternate-blue;
    }
}