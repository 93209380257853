@import '../../../sass/sass-vars.scss';


.wrap {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 60vw;

    .nameInputWrap {
        width: $design-card-width;
        margin-bottom: 1rem;
    }
    
    .designsWrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow-x: scroll;
        grid-gap: 1.25rem;
        gap: 1.25rem;
        margin: -1rem;
        height: $design-card-height + 35px;
        
        & > * {
            margin: 1rem;
        }
    }

    .btnWrap {
        width: $design-card-width;
        margin-top: 2rem;
    }
}
