@import '../sass/sass-vars.scss';

.outerWrap {
    width: 100vw;
    height: 100vh;
    
    .wrap {
        @media screen and (min-width: ($iphone-pro-max + 10)) {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -80%);
            height: 400px;
            padding: 4rem;
            border-radius: 6px;
        }
        
        display: flex;
        flex-direction: column;
        justify-content: center;
        grid-gap: 2rem;
        gap: 2rem;
        align-items: center;
        background-color: whitesmoke;
    
        h2 {
            margin: 0;
            font-family: 'Roboto', sans-serif;
        }
    
        @media screen and (max-width: ($iphone-pro-max + 10)) {
            width: 100%;
            height: 100%;
        }

        button {
            @media screen and (max-width: ($iphone-pro-max + 10)) {
                margin-bottom: 6rem;
            }
        }
    }
}