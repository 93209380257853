@import './sass/sass-vars.scss';
.wrap {
    margin-left: $main-left-space;
    margin-right: $main-right-space;

    @media screen and (max-width: $small-laptop) {
        margin-left: $small-laptop-margin + 3rem;
    }

    @media screen and (max-width: $tablet) {
        margin: 0 $tablet-margin;
    }

    @media screen and (max-width: ($iphone-pro-max + 10)) {
        margin: 0 $iphone-plus-margin;
        padding-top: 2rem;
    }
}