
.mainArea {
    padding-left: 0px !important;
    .sectionWrap {

        max-width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        
        
        section {
            margin-bottom: 1rem;
            .pickerWrap {
                display: flex;
                justify-content: space-between;
            }

            .btnsWrap {
                display: flex;
            }
        }
    }
}
