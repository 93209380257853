.wrap {
    margin-bottom: 2rem;
 

    
    .innerWrap {
        margin: 0;
        
        h2 {
            margin-bottom: 0;
        }
    }
    
}