@import '../../sass/sass-vars.scss';

.wrap {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    margin: 1rem 0 3rem 0;

    @media screen and (max-width: ($iphone-pro-max + 10)) {
        margin: 0 0 3rem 0;
    }

    

    hr {
        width: 100%;
        margin-bottom: 3rem;
    }

    .title {
        display: flex;
        justify-content: space-between;

        h2 {
            margin: 0;
            font-size: 22px;
        }
    }

    .designsWrap {
        
        @media screen and (max-width: $tablet) {
            &::-webkit-scrollbar {
                -webkit-appearance: none;
                width: 4px;
            }
            
            &::-webkit-scrollbar-track {
                background-color: rgba(128, 128, 128, 0.6);
                border-radius: 8px;
            }
            
            &::-webkit-scrollbar-thumb {
                border-radius: 8px;
                background-color: rgba(241, 241, 241, 0.6);
            }
        }
        
        height: $design-card-height + 65px;
        padding-left: 2px;
        display: flex;
        flex-direction: row;
        margin: -1rem;
        overflow-x: scroll;
        overflow-y: hidden;
        & > * {
            margin: 1rem;
        }
    }
}